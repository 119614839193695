
<!-- This example requires Tailwind CSS v3.0+ -->
<div class="isolate bg-white">
    <beca-navigation></beca-navigation>
    <main class="mx-auto overflow-hidden">
        <router-outlet></router-outlet>
    </main>
    <beca-footer></beca-footer>
</div>
  



  