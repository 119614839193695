<div class="max-w-screen-xl mx-auto">
    <div class="block relative">
        <div class="block relative bg-brand-blue pb-32">
    
            
            <!-- <div class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-75 z-10"></div> -->
    
            <!-- <div class="absolute top-0 left-0 w-full h-full bg-brand-blue z-0"></div> -->
            <img class="relative w-1/2 mx-auto md:w-2/3" src="assets/images/brett-jordan-oXjvbhXxm4w-unsplash-transformed.png" alt="">
        </div>
        <div class="block relative mx-auto -mt-32 z-10 md:w-2/3 xl:w-1/2 lg:-mt-48">
            <div class="block relative text-center">
                <h3 class="text-white text-base font-semibold lg:text-xl">Who are we?</h3>
                <h1 class="text-white text-3xl font-bold mt-0 mb-4 lg:text-5xl">{{ env.site.name }}</h1>
            </div>
            <div class="relative bg-gray-900 px-10 py-16 rounded-t-lg">
                <p class="text-white text-sm md:text-base">
                    In 1984, BECA (spanish for scholarship) was realized as a 501 (c)(3) Nonprofit organization. 
                    This accomplishment due to the committed efforts of Co-Founders: 
                    Joe Costa, Bill Dominguez, Dr. Fred Huber, Dr. John MacDonald, David Martínez, Ted Marioncelli, Al Mendoza, Thomas Missettt, Jack Port, Judge Victor Ramirez, and David Ronquillo, Esq
                </p>
            </div>
    
            <div class="block relative">
                <img class="relative w-full" src="http://www.becafoundation.org/resources/covers/beca-foundation-members.jpg" alt="">
            </div>
    
            <div class="relative bg-gray-900 px-10 py-16 rounded-b-lg">
                <p class="text-white text-sm md:text-base">
                    Since 1984, over 830 awards have been allocated to students totaling approximately $1.8 million. BECA's focus continues to be the importance of mentoring to assure success. 
                    Past recipients can now be found in various job markets having pursued a wide range of careers.
                </p>
            </div>
        </div>
    </div>
    
    
    
    
    <div class="mx-4 lg:mx-0">
        <section class="max-w-2xl md:w-2/3 xl:w-1/2 mx-auto my-16">
            <div class="text-center m-8">
                <h1 class="text-4xl text-indigo-500 font-bold">BECA Members</h1>
            </div>
        
            <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-4">
                <div class="w-full rounded-3xl bg-indigo-100 py-8 lg:max-w-xs">
                    <div class="block">
                        <img class="w-32 h-32 mx-auto mb-2 border-8 border-indigo-500 rounded-full" 
                            src="assets/images/Adriana_Andres_Paulson_BOD-1-600x600.png" alt="Adriana Andr&eacute;s-Paulson"/>
                        
                        <div class="text-center">
                            <h5 class="block text-sm font-semibold text-indigo-500 uppercase">Adriana Andr&eacute;s-Paulson</h5>
                            <span class="block text-sm text-gray-700 mt-1 mb-4">President</span>
                            <a class="block text-sm text-gray-700 underline"
                                target="_blank" rel="noreferrer noopener" href="mailto:webmaster@becafoundation.org?cc=scholarship@becafoundation.org">email@becafoundation.com</a>
                        </div>
                    </div>
                </div>
        
                <div class="w-full rounded-3xl bg-indigo-100 py-8 lg:max-w-xs">
                    <div class="block">
                        <img class="w-32 h-32 mx-auto mb-2 border-8 border-indigo-500 rounded-full" 
                            src="assets/images/Andres_Martin.jpg" alt="Andr&eacute;s Mart&iacute;n"/>
                        
                        <div class="text-center">
                            <h5 class="block text-sm font-semibold text-indigo-500 uppercase">Andr&eacute;s Mart&iacute;n</h5>
                            <span class="block text-sm text-gray-700 mt-1 mb-4">Vice-President of External Business</span>
                            <a class="block text-sm text-gray-700 underline"
                                target="_blank" rel="noreferrer noopener" href="mailto:webmaster@becafoundation.org?cc=scholarship@becafoundation.org">email@becafoundation.com</a>
                        </div>
                    </div>
                </div>
        
        
                <div class="w-full rounded-3xl bg-indigo-100 py-8 lg:col-span-2">
                    <div class="block">
                        <div class="relative w-32 h-32 mx-auto mb-2 border-8 border-yellow-500 bg-yellow-300 rounded-full">
                            <span class="absolute top-0 left-0 text-gray-700 text-6xl font-bold px-10 py-2">?</span>
                        </div>
                        <!-- <img class="w-32 h-32 mx-auto mb-2 border-8 border-indigo-500 rounded-full" 
                            src="../../assets/images/Andres_Martin.jpg" alt="Bonnie image"/> -->
                        
                        <div class="text-center">
                            <h5 class="block text-sm font-semibold text-indigo-500 uppercase"><span class="stroke-brand-green py-2 px-2 text-white">Vacant</span></h5>
                            <span class="block text-sm text-gray-700 mt-1 mb-4">Secretary</span>
                            <a class="block text-sm text-gray-700 underline"
                                target="_blank" rel="noreferrer noopener" href="mailto:webmaster@becafoundation.org?cc=scholarship@becafoundation.org">email@becafoundation.com</a>
                        </div>
                    </div>
                </div>
                
                <div class="w-full rounded-3xl bg-indigo-100 py-8 lg:max-w-xs">
                    <div class="block">
                        <div class="relative w-32 h-32 mx-auto mb-2 border-8 border-indigo-500 rounded-full">
                            <img class="block m-6 w-16" 
                            src="assets/images/no-photos.png" alt="Mary L. Gonz&aacute;lez Ed.D."/>
                        </div>
                        
                        <div class="text-center">
                            <h5 class="block text-sm font-semibold text-indigo-500 uppercase">Mary L. Gonz&aacute;lez Ed.D.</h5>
                            <span class="block text-sm text-gray-700 mt-1 mb-4">Vice-President of Internal Business</span>
                            <a class="block text-sm text-gray-700 underline" 
                                target="_blank" rel="noreferrer noopener" href="mailto:webmaster@becafoundation.org?cc=scholarship@becafoundation.org,Marialuisagonz@gmail.com">email@becafoundation.com</a>
                        </div>
                    </div>
                </div>
        
                <div class="w-full rounded-3xl bg-indigo-100 py-8 lg:max-w-xs">
                    <div class="block">
                        <div class="relative w-32 h-32 mx-auto mb-2 border-8 border-indigo-500 rounded-full">
                            <img class="block m-6 w-16" 
                            src="assets/images/no-photos.png" alt="George L&oacute;pez"/>
                        </div>
                        
                        <div class="text-center">
                            <h5 class="block text-sm font-semibold text-indigo-500 uppercase">George L&oacute;pez</h5>
                            <span class="block text-sm text-gray-700 mt-1 mb-4">Treasurer</span>
                            <a class="block text-sm text-gray-700 underline"
                                target="_blank" rel="noreferrer noopener" href="mailto:webmaster@becafoundation.org?cc=scholarship@becafoundation.org">email@becafoundation.com</a>
                        </div>
                    </div>
                </div>
        
                <div class="w-full rounded-3xl bg-indigo-100 py-8 lg:max-w-xs">
                    <div class="block">
                        <img class="w-32 h-32 mx-auto mb-2 border-8 border-indigo-500 rounded-full" 
                            src="assets/images/Yessenia_Balcazar-transformed.png" alt="Yesenia Balcazar"/>
                        
                        <div class="text-center">
                            <h5 class="block text-sm font-semibold text-indigo-500 uppercase">Yesenia Balcazar</h5>
                            <span class="block text-sm text-gray-700 mt-1 mb-4">Board Member</span>
                            <a class="block text-sm text-gray-700 underline"
                                target="_blank" rel="noreferrer noopener" href="mailto:webmaster@becafoundation.org?cc=scholarship@becafoundation.org">email@becafoundation.com</a>
                        </div>
                    </div>
                </div>
        
        
                <div class="w-full rounded-3xl bg-indigo-100 py-8 lg:max-w-xs">
                    <div class="block">
                        <img class="w-32 h-32 mx-auto mb-2 border-8 border-indigo-500 rounded-full" 
                            src="assets/images/Jonathan_Justo.jpg" alt="Jonathan Justo"/>
                        
                        <div class="text-center">
                            <h5 class="block text-sm font-semibold text-indigo-500 uppercase">Jonathan Justo</h5>
                            <span class="block text-sm text-gray-700 mt-1 mb-4">Advisory Board</span>
                            <a class="block text-sm text-gray-700 underline"
                                target="_blank" rel="noreferrer noopener" href="mailto:webmaster@becafoundation.org?cc=scholarship@becafoundation.org">email@becafoundation.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    
    
    
    <!-- <ul class="max-w-md mx-auto my-12">
        <li class="pb-3 sm:pb-4">
            <div class="flex items-center space-x-4">
                <div class="flex-shrink-0">
                    <img class="w-8 h-8 rounded-full" src="http://www.becafoundation.org/resources/covers/beca-foundation-members.jpg" alt="Neil image">
                </div>
                <div class="flex-1 min-w-0">
                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                        Adriana Andrés-Paulson
                    </p>
                    <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                        email@becafoundation.com
                    </p>
                </div>
                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    $320
                </div>
            </div>
        </li>
        <li class="py-3 sm:py-4">
            <div class="flex items-center space-x-4">
                <div class="flex-shrink-0">
                    <img class="w-8 h-8 rounded-full" src="http://www.becafoundation.org/resources/covers/beca-foundation-members.jpg" alt="Neil image">
                </div>
                <div class="flex-1 min-w-0">
                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                        Andrés Martín
                    </p>
                    <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                        email@becafoundation.com
                    </p>
                </div>
                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    $3467
                </div>
            </div>
        </li>
        <li class="py-3 sm:py-4">
            <div class="flex items-center space-x-4">
                <div class="flex-shrink-0">
                    <img class="w-8 h-8 rounded-full" src="http://www.becafoundation.org/resources/covers/beca-foundation-members.jpg" alt="Neil image">
                </div>
                <div class="flex-1 min-w-0">
                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                        Mary L. González Ed.D.
                    </p>
                    <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                        email@becafoundation.com
                    </p>
                </div>
                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    $67
                </div>
            </div>
        </li>
        <li class="py-3 sm:py-4">
            <div class="flex items-center space-x-4">
                <div class="flex-shrink-0">
                    <img class="w-8 h-8 rounded-full" src="http://www.becafoundation.org/resources/covers/beca-foundation-members.jpg" alt="Neil image">
                </div>
                <div class="flex-1 min-w-0">
                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                        George López
                    </p>
                    <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                        email@becafoundation.com
                    </p>
                </div>
                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    $2367
                </div>
            </div>
        </li>
        <li class="pt-3 pb-0 sm:pt-4">
            <div class="flex items-center space-x-4">
                <div class="flex-shrink-0">
                    <img class="w-8 h-8 rounded-full" src="http://www.becafoundation.org/resources/covers/beca-foundation-members.jpg" alt="Neil image">
                </div>
                <div class="flex-1 min-w-0">
                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                        Yesenia Balcazar (Co-Scholarship Chair)
                    </p>
                    <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                        email@becafoundation.com
                    </p>
                </div>
                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    $367
                </div>
            </div>
        </li>
    </ul> -->
         
</div>
