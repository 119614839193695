<div class="max-w-screen-xl mx-auto">
    <!-- General Scholarship -->
    <div class="block">
        <div class="flex flex-col lg:flex-row">
            <div class="relative block aspect-video overflow-hidden md:aspect-video lg:w-1/2 lg:m-8 lg:rounded-lg">
                <img class="absolute w-full top-0 left-0 z-0 lg:h-full" src="assets/images/beca-foundation-members.jpg">
            </div>
            
            <div class="relative flex items-center lg:p-8 lg:mt-0 lg:w-1/2">
                <div class="mx-auto max-w-3xl px-4 py-20 lg:p-20 xl:p-30">
                    <div class="text-center">
                        <div>
                            <div class="mb-8">
                                <div class="inline relative overflow-hidden rounded-full bg-white border border-gray-400 py-2 text-xs leading-6 whitespace-no-wrap md:text-sm">
                                    <span class="text-gray-600 mx-4">Application period</span>
                                    <span class="text-gray-600 bg-yellow-500 px-4 py-2 rounded-3xl">
                                        {{ period.from | date:'mediumDate' }} &ndash; {{ period.to | date:'mediumDate' }}
                                    </span>
                                </div>
                            </div>
                            <h1 class="text-xl font-bold tracking-tight md:text-4xl lg:text-4xl xl:text-5xl">
                                BECA General Scholarship
                            </h1>
                            <p class="mt-6 text-lg leading-8 md:text-2xl">
                                <span class="stroke-brand-green text-white font-semibold p-2">$500 - $1,000</span>
                            </p>
                            <!-- <div class="mt-8 flex gap-x-4 justify-center">
                                <a class="inline-block rounded-lg bg-brand-blue px-4 py-2 text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-700" routerLink="scholarships">
                                    Scholarships <span class="text-indigo-200" aria-hidden="true">&rarr;</span>
                                </a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-col lg:flex-row">
            <div class="relative block lg:aspect-square lg:w-1/2">
                <div class="relative px-8 py-16 lg:p-16 lg:flex lg:flex-col lg:items-center lg:justify-center lg:absolute lg:top-0 lg:left-0 w-full h-full">
                    <div class="relative block">
                        <h1 class="text-lg font-bold mb-8 md:text-2xl">Eligibility</h1>
                        <p class="text-sm text-gray-700 md:text-base">
                            Requires an applicant to be a 2019 San Diego County high school graduate who is entering college in the fall 2019. 
                            Eligible applicants may pursue their education anywhere in the United States and major in any academic area.
                        </p>
                    </div>
        
                    <div class="mt-8 flex gap-x-4 justify-center">
                        <a class="inline-block rounded-lg bg-brand-blue px-4 py-2 text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-700"
                            href="https://docs.google.com/forms/d/1UHQtuh7bZ3BGzQSM_IlGX4RY56Olhx1l6cSO3fmgZSY/viewform?c=0" *ngIf="period.open">
                            Apply Now <span class="text-indigo-200" aria-hidden="true">&rarr;</span>
                        </a>

                        <span class="inline-block rounded-lg bg-brand-blue px-4 py-2 text-sm font-semibold leading-7 text-white shadow-sm md:text-base hover:bg-indigo-700"
                            *ngIf="!period.open">
                            Application Currently Closed
                        </span>
                    </div>
                </div>
            </div>
        
            <div class="relative block lg:aspect-square lg:w-1/2 lg:m-8 lg:rounded-lg lg:bg-brand-blue">
                <div class="relative px-8 py-16 lg:p-16 lg:flex lg:flex-col lg:items-center lg:justify-center lg:absolute lg:top-0 lg:left-0 w-full h-full">
                    <div class="relative block xl:px-16">
                        <h1 class="text-lg font-bold mb-8 text-gray-900 md:text-2xl lg:text-white">Requirement</h1>
                        <ul class="mb-8 space-y-2 text-left text-gray-700 lg:text-gray-100 lg:space-y-4">
                            <li class="flex items-center space-x-3">
                                <!-- Icon -->
                                <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-sm md:text-base">Online applications</span>
                            </li>
                            <li class="flex items-center space-x-3">
                                <!-- Icon -->
                                <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-sm md:text-base">Two letters of recommendation</span>
                            </li>
                            <li class="flex items-center space-x-3">
                                <!-- Icon -->
                                <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-sm md:text-base">Personal statement</span>
                            </li>
                            <li class="flex items-center space-x-3">
                                <!-- Icon -->
                                <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-sm md:text-base">Academic transcripts</span>
                            </li>
                            <li class="flex items-center space-x-3">
                                <!-- Icon -->
                                <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-sm md:text-base">Estimated Family Contribution (EFC) based on your Student Aid Report (SAR)</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <!-- Alice Newell Scholarship -->
    <div class="block">
        <div class="flex flex-col lg:flex-row-reverse">
            <div class="relative block aspect-video overflow-hidden lg:aspect-square lg:w-1/2 lg:m-8 lg:rounded-lg">
                <img class="absolute w-full top-0 left-0 z-0 lg:h-full" src="assets/images/currivan.jpg">
            </div>
            
            <div class="relative flex items-center lg:p-8 lg:mt-0 lg:w-1/2">
                <div class="mx-auto max-w-3xl px-4 py-20 lg:p-20 xl:p-30">
                    <div class="text-center">
                        <div>
                            <div class="mb-8">
                                <div class="inline relative overflow-hidden rounded-full bg-white border border-gray-400 py-2 text-xs leading-6 whitespace-no-wrap md:text-sm">
                                    <span class="text-gray-600 mx-4">Application period</span>
                                    <span class="text-gray-600 bg-yellow-500 px-4 py-2 rounded-3xl">
                                        {{ period.from | date:'mediumDate' }} &ndash; {{ period.to | date:'mediumDate' }}
                                    </span>
                                </div>
                            </div>
                            <h1 class="text-xl font-bold tracking-tight md:text-4xl lg:text-4xl xl:text-5xl">
                                Alice Newell Joslyn Medical Scholarship
                            </h1>
                            <p class="mt-6 text-lg leading-8 md:text-2xl">
                                <span class="stroke-brand-blue text-white font-semibold p-2">$500 - $2,000</span>
                            </p>
                            <!-- <div class="mt-8 flex gap-x-4 justify-center">
                                <a class="inline-block rounded-lg bg-brand-blue px-4 py-2 text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-700" routerLink="scholarships">
                                    Scholarships <span class="text-indigo-200" aria-hidden="true">&rarr;</span>
                                </a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="flex flex-col lg:flex-row">
            <div class="relative block lg:aspect-square lg:w-1/2 lg:m-8 lg:rounded-lg lg:bg-yellow-500">
                <div class="relative px-8 py-16 lg:p-16 lg:flex lg:flex-col lg:items-center lg:justify-center lg:absolute lg:top-0 lg:left-0 w-full h-full">
                    <div class="relative block">
                        <h1 class="text-lg font-bold mb-8 md:text-2xl lg:text-gray-700">Eligibility</h1>
                        <p class="text-sm text-gray-700 mb-4 md:text-base lg:mt-4">
                            Requires an applicant to be entering the Medical/Health Care profession i.e.; dental/medical assistant, nursing, physical therapist, 
                            or seeking their Bachelor of Science, Master's or Doctorate in the health field. 
                        </p>
                        <p class="text-sm text-gray-700 mt-4 md:text-base lg:mt-4">
                            Applicants must be living or attending a high school 
                            or college in San Diego County at the time of application. Eligibility is contingent upon a student's financial need, scholastic determination, 
                            and community/cultural awareness. Scholarship recipients may re-apply contingent on scholastic progress.
                        </p>
                    </div>
        
                    <div class="mt-8 flex gap-x-4 justify-center">
                        <a class="inline-block rounded-lg bg-yellow-600 px-4 py-2 text-base font-semibold leading-7 text-white shadow-sm hover:bg-yellow-700"
                            href="https://docs.google.com/forms/d/e/1FAIpQLSeDQfVFWj0XXfjUyV4LVA40dPzzB_mwOEvZW2dHnYtmIYKhxg/viewform?c=0" *ngIf="period.open">
                            Apply Now <span class="text-white" aria-hidden="true">&rarr;</span>
                        </a>

                        <span class="inline-block rounded-lg bg-yellow-600 px-4 py-2 text-sm font-semibold leading-7 text-white shadow-sm md:text-base hover:bg-yellow-700"
                            *ngIf="!period.open">
                            Application Currently Closed
                        </span>
                    </div>
                </div>
            </div>
        
        
            <div class="relative block lg:aspect-square lg:w-1/2">
                <div class="relative px-8 py-16 lg:p-16 lg:flex lg:flex-col lg:items-center lg:justify-center lg:absolute lg:top-0 lg:left-0 w-full h-full">
                    <div class="relative block xl:px-16">
                        <h1 class="text-lg font-bold mb-8 md:text-2xl">Requirements</h1>
                        <ul class="mb-8 space-y-2 text-left text-gray-700 lg:space-y-4">
                            <li class="flex items-center space-x-3">
                                <!-- Icon -->
                                <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-sm md:text-base">Online application</span>
                            </li>
                            <li class="flex items-center space-x-3">
                                <!-- Icon -->
                                <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-sm md:text-base">Two letters of recommendation</span>
                            </li>
                            <li class="flex items-center space-x-3">
                                <!-- Icon -->
                                <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-sm md:text-base">Personal statement</span>
                            </li>
                            <li class="flex items-center space-x-3">
                                <!-- Icon -->
                                <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-sm md:text-base">Academic transcripts</span>
                            </li>
                            <li class="flex items-center space-x-3">
                                <!-- Icon -->
                                <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-sm md:text-base">Estimated Family Contribution (EFC) based on your Student Aid Report (SAR)</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>