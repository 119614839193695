<div class="max-w-screen-xl mx-auto">
    <div class="flex flex-col lg:flex-row">
        <div class="relative block lg:w-1/2">
            <img class="lg:absolute w-full h-full z-0" src="assets/images/md-duran-1VqHRwxcCCw-unsplash.jpg">
        </div>
        
        <div class="relative block -mt-40 lg:w-1/2 px-6 lg:px-8 lg:mt-0">
            <div class="mx-auto max-w-3xl pt-16 pb-20 xl:py-30">
                <div class="text-center">
                    <div class="mb-16 sm:flex sm:justify-center">
                        <div class="relative overflow-hidden rounded-full bg-white border border-gray-400 py-2 px-4 text-sm leading-6">
                            <span class="text-gray-600 text-xs md:text-base">
                                A Nonprofit Latino Scholarship Organization 
                                <a class="font-semibold text-indigo-600 hover:underline" routerLink="/about">
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                    Read more&rarr;
                                </a>
                            </span>
                        </div>
                    </div>
                    <div>
                        <h1 class="text-xl font-bold tracking-tight sm:text-4xl lg:text-2xl xl:text-4xl">
                            More Than 3 Decades of Making a Difference for Latino Students!
                        </h1>
                        <p class="mt-6 text-sm leading-8 text-gray-600 md:text-lg">
                            Commited To Building Tomorrow's Latino Leaders Through Higher Education
                        </p>
                        <div class="mt-8 flex gap-x-4 justify-center">
                            <a class="inline-block rounded-lg bg-brand-blue px-4 py-2 text-sm font-semibold leading-7 text-white shadow-sm md:text-base hover:bg-indigo-700" routerLink="/scholarships">
                                Scholarships <span class="text-indigo-200" aria-hidden="true">&rarr;</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    
    <div class="mx-4 lg:mx-0">
        <div class="text-center rounded-lg bg-brand-blue max-w-5xl mx-auto my-16">
            <!-- <div class="bg-white">
                <h1 class="text-3xl">A Nonprofit Latino Scholarship Organization</h1>
            </div> -->
            <div class="flex flex-col md:flex-row">
                <div class="md:w-1/3">
                    <div class="py-10 text-white m-4">
                        <span class="text-5xl">1984</span>
                        <p class="text-base -my-2">Established</p>
                    </div>
                </div>
                <div class="md:w-1/3">
                    <div class="py-10 text-white m-4 border-t-2 border-b-2 md:border-t-0 md:border-b-0 md:border-l-2 md:border-r-2">
                        <span class="text-5xl">830+</span>
                        <p class="text-base -my-2">Beneficiaries</p>
                    </div>
                </div>
                <div class="md:w-1/3">
                    <div class="py-10 text-white m-4">
                        <span class="text-5xl">$1.8M+</span>
                        <p class="text-base -my-2">In awards</p>
                    </div>
                </div>
            </div>
        </div>
        
        <!--  CONTAINERS  -->
        <div class="max-w-screen-xl mx-auto block my-16 lg:space-y-32">
            <div class="flex flex-col lg:flex-row-reverse">
                <div class="relative lg:z-0 lg:w-1/2">
                    <div class="lg:max-w-sm lg:mr-auto">
                        <div class="relative block aspect-square rounded-md overflow-hidden md:aspect-2/3 lg:-ml-12 lg:aspect-none">
                            <div class="absolute bottom-0 left-0 lg:relative">
                                <img class="w-full h-full" src="assets/images/krakenimages-Y5bvRlcCx8k-unsplash.jpg">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative flex items-center lg:z-10 lg:w-1/2">
                    <div class="relative bg-white bg-opacity-75 px-4 py-8 lg:p-16 lg:rounded-md lg:shadow-xl">
                        <div class="absolute inset-0 bg-white bg-opacity-50 blur-4 z-0"></div>
                        <div class="relative z-10">
                            <h1 class="text-xl text-gray-900 font-bold md:text-2xl">Commited To Building Tomorrow's Latino Leaders Through Higher Education</h1>
                            <p class="text-sm text-gray-700 font-normal my-6 md:text-base">
                                <span class="stroke-brand-green p-2 text-white">{{ env.site.name }}</span> will encourage and empower aspiring Latino students to pursue higher education through financial and moral support.
                            </p>
                            <p class="text-sm text-gray-700 font-normal my-6 md:text-base">
                                {{ env.site.name }} will help to decrease the drop-out rate among Latino students, making the "Possible Dream" of higher education a reality for Latino students.
                            </p>
        
                            <div class="block mt-8">
                                <a class="inline-block rounded-lg bg-white px-4 py-2 text-sm font-semibold leading-7 text-gray-900 shadow-sm md:text-base hover:bg-brand-blue hover:text-white" routerLink="/about">
                                    Learn more <span aria-hidden="true">&rarr;</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        
            <div class="flex flex-col rounded-lg overflow-hidden my-16 lg:my-0 md:flex-row md:rounded-none">
                <div class="relative flex items-center md:z-10 md:w-1/3 lg:w-1/2">
                    <div class="md:max-w-sm md:ml-auto lg:max-w-md">
                        <div class="block bg-yellow-600 md:-mr-12 md:rounded-md md:shadow-md">
                            <img src="assets/images/kenny-eliason-maJDOJSmMoo-unsplash-transformed.png">
                        </div>
                    </div>
                </div>
                <div class="relative md:z-0 md:w-2/3 lg:w-1/2">
                    <div class="relative bg-yellow-500 px-8 py-8 lg:py-16 lg:pl-24 pr-8 md:rounded-md">
                        <h1 class="text-xl text-gray-700 font-bold md:text-3xl">Education & finances</h1>
                        <p class="text-sm text-gray-700 font-normal my-6 md:text-base">
                            <span class="stroke-brand-blue p-2 text-white">{{ env.site.name }}</span> will encourage and empower aspiring Latino students to pursue higher education through financial and moral support
                        </p>
                        <p class="text-sm text-gray-700 font-normal md:text-base">
                            BECA believes that every San Diego County Latino high school graduate who has the desire to attend an institution of higher learning, 
                            be provided an opportunity. Education is often tied to finances. Through financial support and mentoring, 
                            BECA encourages and empowers aspiring Latino students to pursue their goals. This enables the strengthening of students for lives of purpose, 
                            service and leadership in our communities, as well as, contribute to the prosperity of our Nation.
                        </p>
                    </div>
                </div>
            </div>
        
        
            <div class="flex flex-col lg:flex-row-reverse">
                <div class="relative lg:z-0 lg:w-1/2">
                    <div class="lg:max-w-sm lg:mr-auto">
                        <div class="relative block aspect-square rounded-md overflow-hidden md:aspect-2/3 lg:-ml-12 lg:aspect-none">
                            <div class="absolute bottom-0 left-0 lg:relative">
                                <img class="w-full h-full" src="assets/images/rut-miit-oTglG1D4hRA-unsplash.jpg">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative flex items-center lg:z-10 lg:w-1/2">
                    <div class="relative bg-white bg-opacity-75 px-4 py-8 lg:p-16 lg:rounded-md lg:shadow-xl">
                        <div class="absolute inset-0 bg-white bg-opacity-50 blur-4 z-0"></div>
                        <div class="relative z-10">
                            <h1 class="text-xl text-gray-900 font-bold md:text-2xl">BECA revels in the success stories of graduates.</h1>
                            <p class="text-sm text-gray-700 font-normal my-6 md:text-base">
                                BECA has accomplished what it has set out to do in 1984 - Make a Difference. Gift Giving, is often demonstrated as a sign of happiness in one's achievement. 
                                If you believe in BECA's purpose and accomplishments, your contribution will be money well spent. To help educate our Latino population is a good investment 
                                and can only bring prosperity to our communities and our Nation as a whole.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
        
        <div class="relative pt-20 pb-10 overflow-hidden rounded-lg lg:rounded-none">
            <div class="absolute top-0 right-0 left-0 bottom-50 lg:bottom-25 bg-brand-blue rounded-b-3xl lg:rounded-b-6xl"></div>
        
            <div class="relative container px-4 mx-auto">
                <div class="load-animation reveal mx-auto mb-12 lg:mb-10 text-center">
                    <h2 class="text-2xl mx-4 my-8 text-white text-center font-bold font-heading leading-tight md:text-4xl lg:text-5xl lg:m-12">
                        Your <span class="inline-block px-2 py-1 relative text-white stroke-brand-green">donations</span> will help our latino population pursue a higher education.
                    </h2>
        
                    <p class="mx-auto mt-6 max-w-2xl leading-8 text-white text-lg lg:text-xl">
                        Make a one-time donation
                    </p>
                </div>
            </div>
        
            <div class="max-w-xl lg:max-w-5xl mx-auto">
                <!-- grid gap-4 lg:grid-cols-5 -->
                <form class="relative mx-4 my-32 lg:max-w-lg lg:mx-auto" method="post" action="https://www.paypal.com/cgi-bin/webscr">
                    <button class="w-full relative p-4 my-2 bg-indigo-500 rounded-lg overflow-hidden lg:shadow-2xl lg:p-0 lg:m-0 lg:aspect-square lg:rounded-full lg:absolute lg:bottom-0 lg:right-0 lg:w-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2 lg:z-10 hover:bg-yellow-500" (click)="setDonationAmount(10000)">
                        <span class="lg:absolute lg:bottom-0 lg:right-0 lg:w-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2 lg:z-10">
                            <h5 class="text-sm text-gray-100 font-semibold">CORPORATE</h5>
                            <p class="text-lg text-white font-bold">$10,000</p>
                        </span>
                    </button>
                    
                    <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-2">
                        <button class="relative p-4 my-2 bg-indigo-500 rounded-lg lg:rounded-none lg:p-0 lg:m-0 lg:aspect-square lg:bg-white lg:rounded-tl-full hover:bg-yellow-500" (click)="setDonationAmount(5000)">
                            <span class="lg:absolute top-0 left-0">
                                <h5 class="text-sm text-gray-100 font-semibold">PADRINO</h5>
                                <p class="text-lg text-white font-bold">$5,000</p>
                            </span>
                        </button>
                        <button class="relative p-4 my-2 bg-indigo-500 rounded-lg lg:rounded-none lg:p-0 lg:m-0 lg:aspect-square lg:bg-white lg:rounded-tr-full hover:bg-yellow-500" (click)="setDonationAmount(1000)">
                            <span class="lg:absolute top-0 right-0">
                                <h5 class="text-sm text-gray-100 font-semibold">AMIGO</h5>
                                <p class="text-lg text-white font-bold">$1,000</p>
                            </span>
                        </button>
                        <button class="relative p-4 my-2 bg-indigo-500 rounded-lg lg:rounded-none lg:p-0 lg:m-0 lg:aspect-square lg:bg-white lg:rounded-bl-full hover:bg-yellow-500" (click)="setDonationAmount(500)">
                            <span class="lg:absolute bottom-0 left-0">
                                <h5 class="text-sm text-gray-100 font-semibold">MEMORIAL</h5>
                                <p class="text-lg text-white font-bold">$500</p>
                            </span>
                        </button>
                        <button class="relative p-4 my-2 bg-indigo-500 rounded-lg lg:rounded-none lg:p-0 lg:m-0 lg:aspect-square lg:bg-white lg:rounded-br-full hover:bg-yellow-500" (click)="setDonationAmount(10000)">
                            <span class="lg:absolute bottom-0 right-0">
                                <h5 class="text-sm text-gray-100 font-semibold">CONTRIBUTOR</h5>
                                <p class="text-lg text-white font-bold">$150</p>
                            </span>
                        </button>
                    </div>
        
                    <!-- <button class="bg-white text-left rounded-md pt-5 pb-4 pl-6 pr-2" (click)="setDonationAmount(10000)">
                        <h5 class="text-sm text-gray-600 font-semibold">CORPORATE</h5>
                        <p class="text-lg font-bold">$10,000</p>
                    </button>
                    <button class="bg-white text-left rounded-md pt-5 pb-4 pl-6 pr-2" (click)="setDonationAmount(5000)">
                        <h5 class="text-sm text-gray-600 font-semibold">PADRINO</h5>
                        <p class="text-lg font-bold">$5,000</p>
                    </button>
                    <button class="bg-white text-left rounded-md pt-5 pb-4 pl-6 pr-2" (click)="setDonationAmount(1000)">
                        <h5 class="text-sm text-gray-600 font-semibold">AMIGO</h5>
                        <p class="text-lg font-bold">$1,000</p>
                    </button>
                    <button class="bg-white text-left rounded-md pt-5 pb-4 pl-6 pr-2" (click)="setDonationAmount(500)">
                        <h5 class="text-sm text-gray-600 font-semibold">MEMORIAL</h5>
                        <p class="text-lg font-bold">$500</p>
                    </button>
                    <button class="bg-white text-left rounded-md pt-5 pb-4 pl-6 pr-2" (click)="setDonationAmount(150)">
                        <h5 class="text-sm text-gray-600 font-semibold">CONTRIBUTOR</h5>
                        <p class="text-lg font-bold">$150</p>
                    </button> -->
        
        
                    <input type="hidden" name="cmd" value="_donations">
                    <input type="hidden" name="business" value="webmaster@becafoundation.org">
                    <input type="hidden" name="amount" [value]="selectedDonationAmount">
                    <input type="hidden" name="lc" value="US">
                    <input type="hidden" name="item_name" [value]="env.site.name">
                    <input type="hidden" name="currency_code" value="USD">
                    <input type="hidden" name="bn" value="PP-DonationsBF:btn_donate_LG.gif:NonHostedGuest">
                </form>
        
        
        
                <div class="relative">
                    <!-- <div class="py-32 mx-auto max-w-5xl sm:text-center">
                        <h2 class="text-lg font-semibold leading-8 text-white">Look no further</h2>
                        <p class="mx-auto mt-6 max-w-2xl leading-8 text-white text-lg lg:text-xl">
                            You’ll no longer have to spend hours online, hopping search engines to find a needle in the haystack.
                        </p>
                    </div> -->
        
                    <div class="bg-white flex-cols shadow-lg overflow-hidden lg:rounded-lg lg:flex lg:flex-row lg:items-center">
                        <div class="flex-1 px-8 py-16 lg:w-1/2 lg:px-12 lg:py-32">
                            <form class="text-center" method="post" action="https://www.paypal.com/cgi-bin/webscr">
                                <h3 class="mb-6 font-bold text-3xl md:text-4xl">Make a donation</h3>
        
                                <input type="hidden" name="cmd" value="_donations">
                                <input type="hidden" name="business" value="webmaster@becafoundation.org">
                                <input type="hidden" name="amount" value="0">
                                <input type="hidden" name="lc" value="US">
                                <input type="hidden" name="item_name" [value]="env.site.name">
                                <input type="hidden" name="currency_code" value="USD">
                                <input type="hidden" name="bn" value="PP-DonationsBF:btn_donate_LG.gif:NonHostedGuest">
        
                                <div class="max-w-sm px-2 mx-auto mb-4">
                                    <button class="block h-full w-full py-6 px-6 text-center rounded-lg active border-2  transition-shadow duration-75 hover:shadow-md" alt="PayPal - The safer, easier way to pay online!">
                                        <img class="mb-2 mx-auto h-7" src="assets/logos/paypal.png" alt="Pay with PayPal">
                                        <p class="text-xs">Pay with PayPal™</p>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="flex-1 m-6 px-8 py-16 lg:w-1/2 lg:px-8 lg:py-8 bg-gray-100 rounded-lg">
                            <div class="grid grid-cols-1 gap-y-8 md:gap-y-6">
                                <div class="relative flex flex-col lg:flex-row gap-6">
                                    <div class="flex flex-row text-red-500 items-top">
                                        <!-- Heroicon name: outline/globe-alt -->
                                        <svg class="inline h-8 w-8 items-center justify-center" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                                        </svg>
                                    </div>
                                    <div class="sm:min-w-0 sm:flex-1">
                                        <p class="font-semibold leading-8 text-base text-gray-900">Endowment Interest Income</p>
                                        <p class="leading-6 text-gray-700 text-sm">
                                            You can direct your annual gift be placed in an Endowment Fund, the principal of which is invested, with the interest income allotted to annual scholarships.
                                        </p>
                                    </div>
                                </div>
            
                                <div class="relative flex flex-col lg:flex-row gap-6">
                                    <div class="flex flex-row text-red-500 items-top">
                                        <!-- Heroicon name: outline/scale -->
                                        <svg class="inline h-8 w-8 items-center justify-center" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
                                        </svg>
                                    </div>
                                    <div class="sm:min-w-0 sm:flex-1">
                                        <p class="font-semibold leading-8 text-base text-gray-900">Annual Gift</p>
                                        <p class="leading-6 text-gray-700 text-sm">
                                            An annual gift is tax deductible and can be designated as a gift in recognition of an individual(s) or as a memorial gift.
                                        </p>
                                    </div>
                                </div>
            
                                <div class="relative flex flex-col lg:flex-row gap-6">
                                    <div class="flex flex-row text-red-500 items-top">
                                        <!-- Heroicon name: outline/bolt -->
                                        <svg class="inline h-8 w-8 items-center justify-center" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                                        </svg>
                                    </div>
                                    <div class="sm:min-w-0 sm:flex-1">
                                        <p class="font-semibold leading-8 text-base text-gray-900">Deferred Gift</p>
                                        <p class="leading-6 text-gray-700 text-sm">
                                            A charitable bequest through your will. Such gifts also include charitable annuities and trusts, which provide income to the donor during the owner's lifetime and life insurance arrangements. 
                                            This gift can provide tax savings to the donor.
                                        </p>
                                    </div>
                                </div>
            
                                <div class="relative flex flex-col lg:flex-row gap-6">
                                    <div class="flex flex-row text-red-500 items-top">
                                        <!-- Heroicon name: outline/bolt -->
                                        <svg class="inline h-8 w-8 items-center justify-center" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                                        </svg>
                                    </div>
                                    <div class="sm:min-w-0 sm:flex-1">
                                        <p class="font-semibold leading-8 text-base text-gray-900">Permanent Legacy Gift</p>
                                        <p class="leading-6 text-gray-700 text-sm">
                                            A special named scholarship to recognize an individual, family, institution, etc. that has left an inspiration and/or legacy to be remembered.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>